<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat>
                        <app-header :details="cardDetail"></app-header>


                        <div v-if="showAlert">
                            <alert :message="alertMessage"
                                   :type="alertType"></alert>
                        </div>
                        <!-- <v-form class="multi-col-validation"
                                lazy-validation> -->

                        <v-stepper v-model="step"
                                   class="custom">
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <div class="mx-1 pt-4">
                                        <BackAction :text="text"
                                                    :output="output" />
                                    </div>
                                    <v-row class=" mx-7">
                                    </v-row>

                                    <v-row class="mx-4 mt-10"
                                           justify="space-between">
                                        <v-col lg="6"
                                               class="">
                                            <p class="text-black font-weight-semibold mb-0"
                                               style="font-size: 19px;">{{ $t('Let us know your Card issue') }}</p>
                                            <span style="font-size: 14px;">
                                                {{ $t('Card ending -') }} {{ lastFourDigits }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-form v-model="isFormValid">
                                        <v-card class="card-issue-background mt-4 mx-5">
                                            <v-row class="pa-7">
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="12"
                                                       sm="12">
                                                    <v-row>
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="8"
                                                               sm="12">
                                                            <div style="font-size: 14px;"
                                                                 class="text-color-black">
                                                                {{ $t('Preferred contact method') }}
                                                            </div>
                                                            <v-select v-if="selectedLanguage == 'en'"
                                                                      :items="items"
                                                                      solo
                                                                      outlined
                                                                      :rules="genericRules"
                                                                      v-model="contactMethod"
                                                                      class="mt-3 method"
                                                                      :label="$t('Preferred contact method')"></v-select>
                                                            <v-select v-if="selectedLanguage == 'ar'"
                                                                      :items="itemsAr"
                                                                      solo
                                                                      outlined
                                                                      :rules="genericRules"
                                                                      v-model="contactMethod"
                                                                      class="mt-3 method"
                                                                      :label="$t('Preferred contact method')"></v-select>
                                                        </v-col>
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="8"
                                                               sm="12">
                                                            <div style="font-size: 14px;"
                                                                 class="text-color-black">
                                                                {{ $t('Please Select Reason') }}
                                                            </div>
                                                            <v-select v-if="selectedLanguage == 'en'"
                                                                      :items="reasons"
                                                                      solo
                                                                      outlined
                                                                      :rules="genericRules"
                                                                      v-model="reason"
                                                                      class="mt-3"
                                                                      :label="$t('Please Select Reason')"></v-select>
                                                            <v-select v-if="selectedLanguage == 'ar'"
                                                                      :items="reasonsAr"
                                                                      solo
                                                                      outlined
                                                                      :rules="genericRules"
                                                                      v-model="reason"
                                                                      class="mt-3"
                                                                      :label="$t('Please Select Reason')"></v-select>
                                                        </v-col>
                                                    </v-row>


                                                    <div class="d-flex justify-space-between mt-6">
                                                        <p style="font-size: 14px;"
                                                           class="mb-2 text-color-black">
                                                            {{ $t('Additional information*') }}
                                                        </p>
                                                        <p class="mb-2 text-black font-weight-regular">
                                                            {{ information.length }}{{ $t('/500') }}
                                                        </p>
                                                    </div>
                                                    <v-row>
                                                        <v-col lg="12">
                                                            <v-textarea v-model="information"
                                                                        solo
                                                                        maxlength="500"
                                                                        :rules="noSpecialCharsRule"
                                                                        outlined
                                                                        :label="$t('Add Message')">
                                                                <template #message="{ message }">
                                                                    {{ $t(message) }}
                                                                </template>
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>

                                                        <v-col cols="12"
                                                               lg="4"
                                                               md="6"
                                                               sm="12">
                                                            <v-btn color="primary"
                                                                   type="button"
                                                                   x-large
                                                                   block
                                                                   :disabled="!isFormValid"
                                                                   @click="approvedIssue()">
                                                                {{ $t('Submit Issue') }}
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12"
                                                               lg="4"
                                                               md="6"
                                                               sm="12">
                                                            <v-btn class="text-blue"
                                                                   type="button"
                                                                   style="border: 1px solid #016FD0;"
                                                                   x-large
                                                                   @click="back"
                                                                   block>
                                                                {{ $t('Back') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </v-card>
                                    </v-form>
                                </v-stepper-content>

                                <v-stepper-content step="2">

                                    <v-row class=" success-message">
                                        <v-col lg="12"
                                               class="text-center">
                                            <div class="circle">
                                                <img src="../../assets/images/Success.png"
                                                     alt=""
                                                     width="70px">
                                            </div>

                                            <h2 style="font-size: 20px;"
                                                class="mt-3">
                                                {{ $t('Thank you for letting us know about your Card issue') }} </h2>
                                            <p style="font-size: 14px;"
                                               class="mt-5">
                                                {{ $t(`cardIssueSuccessText`) }}
                                            </p>

                                            <v-row class="pa-12">
                                                <v-col lg="12"
                                                       class="text-center">
                                                    <v-btn class=""
                                                           style="width: 100%;"
                                                           color="primary"
                                                           @click="goback"
                                                           type="button">
                                                        {{ $t('Done') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                        <!-- </v-form> -->
                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline


import soapServices from '@/services/soap-services'
import store from '@/store';
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services';
import alert from '@/views/alert.vue';
import BackAction from '@/@core/BackAction.vue';
import getArabicNumbers from '@/@core/utils/getArabicNumbers';
import soapErrorMessages from '@/@core/utils/soap-error-messages';
import AppHeader from '../test/AppHeader.vue';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        AppHeader,
        alert,
        BackAction
    },
    data: () => ({
        analytics: getAnalytics(),
        step: 1,
        dialog: false,
        isFormValid: false,
        radioGroup: 0,
        allCards: [],
        selectedCard: null,
        cards: [],
        text: 'Back to Card Details',
        output: false,
        date: null,
        items: ['Via Mobile', 'Via Email'],
        itemsAr: ['عبر الهاتف المحمول', 'عبر البريد الالكتروني'],
        reasonsAr: ['الإبلاغ عن سرقة / ضياع بطاقتي', 'البطاقة تالفة', 'لم أستلم بطاقتي', 'لقد كنت ضحية للاحتيال', 'تم الاستيلاء على البطاقة بواسطة ماكينة الصراف الآلي', 'أخرى'],
        reasons: ['Card is lost/stolen', 'Card is damaged', 'I didn’t receive my Card', 'I’ve been a victim of fraud', 'Card has been captured by ATM', 'Other'],
        date1: null,
        menu: false,
        modal: false,
        lastFourDigits: null,
        menu2: false,
        menu3: false,
        contactMethod: null,
        reason: null,
        alertMessage: '',
        selectedLanguage: 'en',
        alertType: 'error',
        showAlert: false,
        // engCount: null,
        information: '',
        // counter: 0,
        genericRules: [
            v => !!v || 'Field is required'
        ],

        noSpecialCharsRule: [
            (value) => !!value || 'Field is required',
            (value) =>
            {
                const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
                if (specialCharsRegex.test(value))
                {
                    return 'Special characters are not allowed.';
                }
                return true;
            }
        ]
    }),
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.cardDetail = store.get('card/details');
        this.$route.params.cardType == 'primary' ? this.cardDetail = store.get('card/details') : this.cardDetail = store.get('card/supplementory')
        this.selectedCard = store.get('card/details');
        this.lastFourDigits = this.selectedCard?.Last4Digits
        window.scrollTo(0, 0);
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    methods: {
        goback()
        {
            window.history.back();
            window.scrollTo(0, 0);
        },
        back()
        {
            window.history.back();
            this.$emit('back')
        },
        approvedIssue()
        {
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                clientCode: store.get('requestKeys/ClientCode'),
                cardSrno: this.cardDetail?.primeCardSerno,
                accountNo: this.cardDetail?.accountNo,
                contactMethod: this.contactMethod == 'Mobile' ? 'M' : 'E',
                clientMessage: this.information
            }
            soapServices.cardIssue(obj).then(soapResp =>
            {

                if (soapResp.SRCardNotReceivedResponse.SRCardNotReceivedResult.ServiceRequest.Result.ErrorCode == "000")
                {
                    this.step = 2;
                    window.scrollTo(0, 0);

                    logEvent(this.analytics, 'AMEX_CI_CARD_ISSUE_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                } else
                {
                    logEvent(this.analytics, 'AMEX_CI_CARD_ISSUE_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                    this.showAlert = true;
                    let alertMessage = soapResp.SRCardNotReceivedResponse.SRCardNotReceivedResult.ServiceRequest.Result.ErrorDesc;
                    let errorCode = soapResp.SRCardNotReceivedResponse.SRCardNotReceivedResult.ServiceRequest.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'error'
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }

            })
        },
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    /* box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%); */
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    /* box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%) !important; */
    box-shadow: none;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

.area {
    background-color: white;
}

.success-message {
    width: 40%;
    margin: 0 auto;
}

.circle {
    background: #E2F1FD;
    width: 118px;
    height: 115px;
    margin: 0 auto;
    padding: 31px 0px;
    border-radius: 70px;

    img {
        width: 55px;
    }
}

@media screen and (max-width: 1000px) {
    .success-message {
        width: 100%;
        margin: 0 auto;
    }
}
</style>
;